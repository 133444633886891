import * as React from "react";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import PageLayout from "../components/Layout/PageLayout";
import PageBlock from "../components/Layout/PageBlock";
import Breadcrumb from "../components/Breadcrumb/Breadcrumb";
import { Link } from "gatsby";

import EMERGENCY_RED from "../images/emergency-icon-red.svg";
import EMERGENCY_BLUE from "../images/emergency-icon-blue.svg";
import ERROR_404 from "../images/404-icon.svg";

const ImageContainer = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: calc(2rem - 0.67em);
`;

const Image = styled.img`
    width: clamp(4rem, calc((100% / 3) - 2rem), 8rem);
    padding: 1rem;
`;

const PAGE_TITLE = "Sorry, page not found";

const NotFoundPage = ({ pageContext }) => {
    return (
        <PageLayout pageTitle={PAGE_TITLE} pageContext={pageContext}>
            <Helmet>
                <meta name='description' content='404 Page Not Found for Digital Surveillance Collection from the Australian Federal Police' />
            </Helmet>
            <PageBlock pageContext={pageContext} backgroundColour='white' isFirstBlock>
                <Breadcrumb pageContext={pageContext} />
                <h1>{PAGE_TITLE}</h1>
                <ImageContainer>
                    <Image src={EMERGENCY_RED} alt='Red Emergency Light' placeholder='blurred' loading='lazy' />
                    <Image src={ERROR_404} alt='Bug' placeholder='blurred' loading='lazy' />
                    <Image src={EMERGENCY_BLUE} alt='Blue Emergency Light' placeholder='blurred' loading='lazy' />
                </ImageContainer>
                <p>It is possible that the address is incorrect, or that the page no longer exists.</p>
                <ul>
                    <li>
                        Did you mean to find out{" "}
                        <Link to='/who-we-are' rel='next'>
                            who we are
                        </Link>
                        ?
                    </li>
                    <li>
                        Perhaps you meant to find out about{" "}
                        <Link to='/the-work' rel='next'>
                            the work
                        </Link>
                        ?
                    </li>
                    <li>
                        Maybe you tried to find out about{" "}
                        <Link to='/careers' rel='next'>
                            careers
                        </Link>
                        ?
                    </li>
                    <li>
                        If you still can't find what you're looking for,{" "}
                        <Link to='/contact-us' rel='next'>
                            contact us
                        </Link>
                        .
                    </li>
                </ul>
            </PageBlock>
        </PageLayout>
    );
};

export default NotFoundPage;
